import { Box, Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { API_BASE_URL, SERVICE_IMAGE_URL } from "../../../../apiPaths";
import { useNavigate, Link } from "react-router-dom";
export default function ServiceItem({ data, onRemoveClick, imageURL, refreshServices }) {
  const toast = useToast();
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  let { _id, name, rate, rateType, description, image } = data;
  if (imageURL) image = `${imageURL}/${image}`;
  else image = SERVICE_IMAGE_URL + image;

  async function addToService(data) {
    delete data._id;
    delete data.__v;
    delete data.updatedAt;
    delete data.createdAt;

    const service = new FormData();

    service.append("session_title", data.name);
    service.append("description", data.description);
    service.append("rate_price", data.rate);
    service.append("rate_type", data.tateType);
    service.append("image", data.image);
    try {
      const response = await fetch(`${API_BASE_URL}/service-builder/`, {
        method: 'POST',
        headers: {
          'x-access-token': currentUser.token
        },
        body: service
      });

      if (!response.ok) {
        toast({
          title: "Error adding service template.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error('Network response was not ok');
      }
      toast({
        title: "Service template added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      refreshServices(true)
    } catch (error) {
      console.error('There has been a problem with your add operation:', error);
    }
  }
  return (
    <Flex
      bgColor={"#fff"}
      flexDirection={"column"}
      rounded="8px"
      p={{ base: 5, lg: 5 }}
      boxShadow={"md"}
      m={{ base: 2, lg: 1 }}>
      <Image
        objectFit={"cover"}
        src={image}
        alt="Session Image"
        height={200}
        width={"100%"}
        backgroundSize="cover"
        crossOrigin="anonymous"
      />

      <Box>
        <Flex flexDirection="column" gap="6px" w="100%" paddingRight={"60px"}>
          <Text fontSize="16px" fontWeight="600" mt={3}>
            {name}
          </Text>
          <Text
            fontSize="12px"
            fontWeight="400"
            color="#616161"
            my={0.5}
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "2",
              overflow: "hidden",
            }}
            mb={description.length < 42 ? "16px" : "0"} 
          >
            {description}
          </Text>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={"40px"}
            width="105%"
          >

            <Text fontSize="12px" fontWeight="500">
              {!imageURL && (
                <Text>
                  £{rate || rate > 0 ? rate : currentUser?.flatRate} {rateType === "Per Hour" ? "Per Hour" : "Per Day"}
                </Text>
              )}
            </Text>

            { imageURL ? (
              <Link to={`/yourservices/new?temp-id=${_id}`}>
                <Button
                  bg="black"
                  _hover={{
                    bg: "white",
                    color: "black",
                  }}
                  color="#F2F2F2"
                  rounded="40px"
                  left="90px"
                  py="10px"
                  px="20px"
                  border="1px solid  #2F2F2F"
                  fontSize="14px"
                  fontWeight="600"
                // onClick={() => {
                //   addToService(data)
                // }}
                >
                  Add
                </Button>
              </Link>
            ) : (
              <Link to={`/yourservices/new?id=${_id}`}>
                <Button
                  bg="black"
                  _hover={{
                    bg: "white",
                    color: "black",
                    boxShadow: "0 0 3px black",
                  }}
                  color="#F2F2F2"
                  rounded="40px"
                  left="90px"
                  py="10px"
                  px="20px"
                  border="1px solid  #2F2F2F"
                  fontSize="14px"
                  fontWeight="600"
                // onClick={() => {

                // }}
                >
                  Edit
                </Button>
              </Link>
            )}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
