import React, { useEffect } from "react";
import { useState } from "react";
import {
  FormControl,
  Input,
  Stack,
  Button,
  VStack,
  Center,
  Box,
  Textarea,
  FormLabel,
  Image,
  Flex,
  Select,
  useToast
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SessionHeader from "../../../Headers/SessionHeader";
import { SERVICE_IMAGE_URL, API_BASE_URL } from "../../../../apiPaths";

const signupSchema = yup.object().shape({
  session_title: yup.string().required("Session title is required"),
  description: yup.string().required("Description is required"),
  rate_type: yup.string().required("Rate type is required"),
  cover_image: yup.mixed().required("Cover image is required"),
});

const NewServiceOne = ({
  handleNext,
  handlePrevious,
  setFormData,
  formData,
  isEnoughServices
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
     formData?.cover_image || formData?.image || ""
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ resolver: yupResolver(signupSchema) });
  const profilePicFile = watch("cover_image");
  const profilePicPreviewUrl = profilePicFile?.[0]
    ? URL.createObjectURL(profilePicFile[0])
    : "";

  useEffect(() => {
    setValue("session_title", formData?.session_title || formData?.name);
    setValue("description", formData?.description || "");
    setValue("rate_price", formData?.rate_price || formData?.rate || currentUser.flatRate);
    setValue(
      "rate_type",
      formData?.rate_type || formData?.rateType || "Per Hour"
    );
    setValue("cover_image", formData?.cover_image || "");
    setImagePreviewUrl(formData?.cover_image || "");
  }, [formData, setValue]);


  useEffect(() => {
    if(!formData?.image) setImagePreviewUrl(profilePicPreviewUrl);

    const isFormValid =
      watch("session_title") &&
      watch("description") &&
      watch("rate_type") &&
      (!formData?.image ? profilePicPreviewUrl : true );

    setIsFormValid(isFormValid);
  }, [watch, profilePicPreviewUrl, formData?.image]);

  const onSubmit = async (data) => {
    if(!data.rate_price) data.rate_price = currentUser.flatRate;
    
    setIsLoading(true);
    setFormData(data);
    const updatedData = { ...formData, ...data };
    setFormData(updatedData);
    setIsLoading(false);
    handleNext();
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/service-builder/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': currentUser.token 
        }      
      });

      if (!response.ok) {
        toast({
          title: "Error deleting service.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error('Network response was not ok');
      } else {
        toast({
          title: "Service deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/yourservices");
      }
    } catch(error){
      console.error('Error deleting service:', error);
    }
  };
  return (
    <Box>
      <SessionHeader handleNext={handleNext} handlePrevious={handlePrevious} formOne={true}/>
      <Center mt={{ base: "20px", xl: "40px" }}>
        <Stack
          minW={{ base: "100%", md: "640px" }}
          spacing={{ base: 6, xl: 12 }}>
          <VStack
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            bg="#ffffff"
            color="black"
            position="relative"
            borderTopRadius={{ base: "16px", md: "none" }}
            rounded={{ base: "", md: "lg" }}
            px={{ base: "15px", md: "24px" }}
            py={{ base: "40px", md: "32px" }}>
            <VStack spacing={{ base: "15px", md: "20px" }} w="100%">
              <FormControl>
                <FormLabel fontSize="14px">Display name</FormLabel>
                <Input
                  borderColor={errors.session_title ? "#E1526C" : "border"}
                  rounded="md"
                  _hover={{
                    borderColor: "#616161",
                  }}
                  defaultValue={formData?.session_title || ""}
                  type="text"
                  placeholder="Session title"
                  {...register("session_title")}
                  _placeholder={{
                    color: "text",
                    fontSize: { base: "12px", md: "14px" },
                  }}
                />
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.session_title && (
                    <p>{errors.session_title.message}</p>
                  )}
                </Box>
              </FormControl>
              <FormControl>
                <FormLabel fontSize="14px">Description</FormLabel>
                <Textarea
                  borderColor={errors.description ? "#E1526C" : "border"}
                  rounded="md"
                  _hover={{
                    borderColor: "#616161",
                  }} 
                  defaultValue={formData?.description || ""}
                  type="text"
                  placeholder="Describe this session"
                  {...register("description")}
                  _placeholder={{
                    color: "text",
                    fontSize: { base: "12px", md: "14px" },
                  }}
                />
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.description && <p>{errors.description.message}</p>}
                </Box>
              </FormControl>
              <FormControl>
                <Box
                  as="label"
                  htmlFor="profile_pic_input"
                  w="100%"
                  display="inline-block"
                  border={
                    errors.cover_image
                      ? "1px solid #E1526C"
                      : "1px solid #D2D2D2"
                  }
                  rounded="md"
                  cursor="pointer">
                  {imagePreviewUrl || formData?.image ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap="8">
                      <Box
                        width={{ base: "120px", xl: "240px" }}
                        height={{ base: "60px", xl: "120px" }}>
                        <Image
                          objectFit="contain"
                          src={imagePreviewUrl || SERVICE_IMAGE_URL+formData?.image}
                          alt="Profile Pic"
                          borderRadius="5px 0px 0px 5px"
                          W="100%"
                          h="100%"
                          crossOrigin="anonymous"
                        />
                      </Box>
                      <Box color="#E1526C">Remove</Box>
                    </Box>
                  ) : (
                    // Your SVG placeholder component
                    <Box color="black">
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap="8">
                        <Box
                          width={{ base: "120px", xl: "240px" }}
                          height={{ base: "60px", xl: "120px" }}
                          overflow="hidden">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%" // Set SVG width to 100% of the container
                            height="100%" // Set SVG height to 100% of the container
                            viewBox="0 0 240 120"
                            fill="none"
                            preserveAspectRatio="xMidYMid meet" // This will scale the SVG while preserving its aspect ratio
                          >
                            <rect
                              width="240"
                              height="120"
                              rx="4"
                              fill="#E4E4E4"
                            />
                            <rect
                              x="121.5"
                              y="47.5469"
                              width="24"
                              height="3"
                              transform="rotate(90 121.5 47.5469)"
                              fill="white"
                            />
                            <rect
                              x="108"
                              y="58.0469"
                              width="24"
                              height="3"
                              fill="white"
                            />
                          </svg>
                        </Box>
                        <Box fontSize={{ base: "12px", xl: "16px" }}>
                          Add a cover image
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Input
                  id="profile_pic_input"
                  type="file"
                  style={{ display: "none" }}
                  {...register("cover_image")}
                />
                {errors.cover_image && (
                  <Box color="#E1526C" fontSize="14px" mt="2">
                    <p>{errors.cover_image.message}</p>
                  </Box>
                )}
              </FormControl>
              <Box w="100%">
                <FormLabel>Specify the rate</FormLabel>
                <Flex gap="24px">
                  <FormControl>
                    <Input
                      borderColor={errors.rate_price ? "#E1526C" : "border"}
                      rounded="md"
                      _hover={{
                        borderColor: "#616161",
                      }}
                      type="number"
                      placeholder="Add rate"
                      {...register("rate_price")}
                      // defaultValue={formData?.rate_price || currentUser.flatRate || ""}
                      _placeholder={{
                        color: "text",
                        fontSize: { base: "12px", md: "14px" },
                      }}
                    />
                    <Box color="#E1526C" fontSize="14px" mt="5px">
                      {errors.rate_price && <p>{errors.rate_price.message}</p>}
                    </Box>
                  </FormControl>
                  <FormControl>
                    <Select
                      borderColor={errors.rate_type ? "#E1526C" : "border"}
                      rounded="md"
                      _hover={{
                        borderColor: "#616161",
                      }}
                      {...register("rate_type")}
                      defaultValue={
                        !formData?.rateType ? "Per Hour" : (formData?.rate_type || formData?.rateType)
                      }>
                      <option value="Per Hour">Per hour</option>
                      <option value="Per Day">Per day</option>
                      <option value="Per Week">Per week</option>
                    </Select>
                    <Box color="#E1526C" fontSize="14px" mt="5px">
                      {errors.rate_type && <p>{errors.rate_type.message}</p>}
                    </Box>
                  </FormControl>
                </Flex>
              </Box>
            </VStack>

            <VStack w="100%" mt={{ base: "15px", md: "30px" }}>
              <Button
                color="white"
                rounded="40px"
                w="100%"
                bg={"black"}
                _hover={{
                  bg: !isFormValid ? "" : "black",
                }}
                py="12px"
                fontSize="14px"
                fontWeight="600"
                type="submit"
                isDisabled={!isFormValid}
                isLoading={isLoading}>
                Continue
              </Button>
              {formData?.isService && isEnoughServices && (
                <Button
                  bg="#E1526C"
                  color="white"
                  fontWeight="bold"
                  rounded="20px"
                  w="full"
                  mt="4"
                  onClick={() => handleDelete(formData?._id)}
                  _hover={{
                    color: "white",
                    bg: "#E1526C",
                  }}
                >
                  Delete Service
                </Button>
              )}
              <Button
                mt="15px"
                color="black"
                _hover={{
                  bg: "black",
                  color: "white",
                }}
                rounded="40px"
                w="100%"
                bg="graybtn"
                py="12px"
                fontSize="14px"
                fontWeight="600"
                isLoading={isLoading}
                as={Link}
                to="/yourservices">
                Cancel
              </Button>
            </VStack>
          </VStack>
        </Stack>
      </Center>
    </Box>
  );
};

export default NewServiceOne;
