import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CalendarIcon, TimeIcon } from "@chakra-ui/icons";
import { FaLocationArrow, FaRegCalendar } from "react-icons/fa6";
import { SERVICE_IMAGE_URL, USER_IMAGE_URL } from "../../../../apiPaths";
import StdProfile from "../../../ProfileDetails";
export default function MyBookingList({data, onModifyBooking, bookingId}) {
  const booking = data;
  const currentDate = new Date();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [isBookingCompleted, setIsBookingCompleted] = useState(false); 
  const startDate = new Date(booking?.sessionId?.start);
  const endDate = new Date(booking?.sessionId?.end);
  const formattedDate = startDate.toLocaleDateString("en-GB");
  const startTime = startDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  });
  const endTime = endDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  });
  const formattedTime = `${startTime} - ${endTime}`;

  let buttonLabel = ""; // Default
  if (startDate > currentDate) {
    buttonLabel = "Upcoming"; // Future
  } else if (
    startDate.toDateString() === currentDate.toDateString() &&
    endDate >= currentDate
  ) {
    buttonLabel = "Today"; // Today
  } else if (endDate < currentDate) {
    buttonLabel = "Completed"; // Past
  }
  const image = SERVICE_IMAGE_URL + booking?.serviceId?.image;
  const details = currentUser.roleName == "STUDENT" ? booking?.tutorId : booking?.userId;
  const [showDetailModel, setShowDetailModel] = useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    if (endDate < currentDate) {
      setIsBookingCompleted(true);
    }
  }, [endDate, currentDate]);

  function handleFormOpen(val) {
    setShowDetailModel(val);
  }

  function handleFormClose(val) {
    setShowDetailModel(val);
  }

  function handleModifyBooking(id) {
    onModifyBooking(id);
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box flexDirection={{ base: "column", lg: "row" }}
        alignItems="center"
        w="100%"
        bg="white"
        rounded="8px"
        mt="5px"
        gap={{ base: "14px", lg: "24px" }}
        boxShadow="md"
        p="16px">

        <Box
          width={{ base: "100%", lg: "auto" }}
          color="white"
        >
          <Image
            height="250px"
            width="100%"
            borderRadius="8px"
            objectFit="cover"
            src={image}
            crossOrigin="anonymous"
            alt="Booking Image"
          />
        </Box>

        <Flex flex="1" justifyContent="space-between" alignItems="center" flexDirection={{ base: "column", lg: "row" }} gap={{ base: "10px", lg: "20px" }} p="10px">
          <Flex flexDirection="column" gap="8px">
            <Box fontSize="18px" fontWeight="700" color="gray.700" pb="4px">
              {booking?.serviceId?.name}
            </Box>

            <Flex flexDirection="row" alignItems="center" gap="8px" pb="10px">
              <Image
                height="32px"
                width="32px"
                borderRadius="50%"
                src={booking?.tutorId?.profilePic ? USER_IMAGE_URL + booking?.tutorId?.profilePic : "https://avatars2.githubusercontent.com/u/37842853?v=4"}
                crossOrigin="anonymous"
                alt="Profile Image"
                onClick={() => handleFormOpen(true)}
                cursor="pointer"
              />
              <Box fontSize="15px" fontWeight="600" color="gray.600">
                {booking?.tutorId?.firstName} {booking?.tutorId?.lastName}
              </Box>
            </Flex>

            <Box ml="4px">
              <Flex alignItems="center" gap="10px" fontSize="14px" color="gray.500" pb="4px">
                <FaRegCalendar /> {formattedDate}
              </Flex>
              <Flex alignItems="center" gap="10px" fontSize="14px" color="gray.500" pb="4px">
                <TimeIcon /> {formattedTime}
              </Flex>
              <Flex alignItems="center" gap="10px" fontSize="14px" color="gray.500" pb="4px">
                <FaLocationArrow /> {booking?.sessionId?.sessionLocation}
              </Flex>
            </Box>
          </Flex>
        </Flex>
        <Flex 
          justifyContent={!isBookingCompleted ? "center" : ""} 
          marginLeft={isBookingCompleted ? "10px" : ""}
          alignItems="center"
        >
          <Flex flexDir="row" gap={{ base: "8px", lg: "12px" }} mb={{ base: "30px", lg: "0px" }} w="fit-content">
          <Flex
        flexDir="row"
        gap={{ base: "8px", lg: "12px" }}
        mb={{ base: "30px", lg: "0px" }}
        w="fit-content"
      >
       <Button
          color="white"
          bg="black"
          _hover={{
            bg: "black"
          }}
          rounded="full"
          py="12px"
          px="20px"
          fontSize="14px"
          fontWeight="600"
          cursor="default"
        >
          {buttonLabel}
        </Button>
       { !isBookingCompleted && (
         <Button
            bg="selectbg"
            color="black"
            _hover={{
              bg: "white",
              border: "1px solid black",
            }}
            border="1px solid white"
            rounded="40px"
            py="12px"
            px="40px"
            w={{ base: "100%", lg: "auto" }}
            fontSize="14px"
            fontWeight="600"
            onClick={() => handleModifyBooking(booking._id)}
          >
            Modify
          </Button>
       )}
      </Flex>
          </Flex>
        </Flex>
      </Box>


    </>
  );
}
