import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Text,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import MyCalendar from "../../Calender/StdCalendar/Calender";

export default function BookSessionFormThree({
  handleNext,
  handlePrevious,
  data,
  onClose,
}) {
  const startDate = new Date(data?.start);
  const [open, setOpen] = useState(true);

  const endDate = new Date(data?.end);
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = startDate.toLocaleDateString("en-US", options);
  const startTime = startDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const endTime = endDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedTime = `${startTime} - ${endTime}`;

  return (
    <Box>
      <MyCalendar />
      <Modal
        blockScrollOnMount={false}
        isOpen={open}
        isCentered
        onClose={onClose}
        size={{
          base: "2xl",
          sm: "xl",
          md: "xl",
          lg: "2xl",
          xl: "2xl",
          "2xl": "3xl",
        }}>
        <ModalContent bg={"#fff"} color="black" position="relative" py={5}>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            rounded="8px">
            <Image
              borderRadius="8px 8px 0 0"
              boxSize={220}
              objectFit={"cover"}
              src={data?.serviceId?.image}
              crossOrigin=""
            />
            <Text color="#000" fontSize="24px" fontWeight="600" my={3} pt={8}>
              Booking Confirmed
            </Text>
            <Box
              width={"80%"}
              display="flex"
              justifyContent={"center"}
              flexDirection={"column"}>
              <Text color="#000" fontSize="14px" fontWeight="700" my={1}>
                Details
              </Text>
              <Text color="#000" fontSize="14px" fontWeight="500" my={1}>
                {data?.userId?.firstName} {data?.userId?.lastName}
              </Text>
              <Text color="#000" fontWeight="500" my={1}>
                {data?.serviceId?.name}
              </Text>
              <Text color="#000" fontSize="14px" fontWeight="500" my={1}>
                {formattedDate}, {formattedTime}
              </Text>

              <Box bg="selectbg" p="20px" rounded={"md"} color="#000" my={4}>
                Thanks for booking a session! Please check your email inbox for
                further details about supplying your tracks.
              </Box>
              <Link to="/mybookings">
                <Button
                  bg="black"
                  _hover={{
                    bg: "white",
                    color: "black",
                    border: "1px solid black",
                  }}
                  color="#F2F2F2"
                  rounded="40px"
                  py="15px"
                  mt="20px"
                  px="24px"
                  w="100%"
                  fontSize="14px"
                  fontWeight="600">
                  My bookings
                </Button>
              </Link>
            </Box>
          </Flex>
        </ModalContent>
      </Modal>
    </Box>
  );
}
