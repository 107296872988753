import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../apiPaths";
import CreativeItem from "../Components/FromSidebar/Student/CreativesList/CreativeItem";
import {
  Box,
  Flex,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Image,
  Text,
  Heading,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import logo from '../assests/images/logo.png';

export default function Home() {
  const [userData, setUserData] = useState([]);
  const [isFecth, setIsFetch] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const navigate = useNavigate();

  async function fetchData(q) {
    try {
      const response = await fetch(`${API_BASE_URL}/user/tutors/get?q=${q}`, {
      });
      const users = await response.json();
      setUserData(users.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    if(currentUser && currentUser.roleName == 'TUTOR') {
      localStorage.setItem("selectedButton", 'calendar');
      navigate("/calendar");
    } else {
     localStorage.setItem("selectedButton", 'dashboard'); 
     navigate("/tutor-search");
    }
    const element = document.querySelector('body');
    const input = document.querySelector('body .css-1ngklx');
    if (element) {
      element.style.backgroundColor = "#F2F2F2";
      element.style.color = "black";
    }
    
  }, []);

  const handleChange = (event) => {
    setIsFetch(true);
    fetchData(event.target.value);
  };

  return (
    <Box>
      <Flex
        bg="black"
        p={"10px"}
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Box>
          <Image
            objectFit={"cover"}
            src={logo}
            alt="Logo"
            height={10}
            // width={"100%"}
            backgroundSize="cover"
            crossOrigin="anonymous"
          />
        </Box>

        <Box>
          {!currentUser && (
            <Box>
              <Link to={`/login`}>
                <Button
                  bg="white"
                  _hover={{
                    bg: "white",
                    color: "black",
                    boxShadow: "0 0 3px black",
                  }}
                  color="#000"
                  rounded="40px"
                  py="10px"
                  px="20px"
                  mx="10px"
                  border="1px solid  #2F2F2F"
                  fontSize="14px"
                  fontWeight="600"
                >
                  Login
                </Button>
              </Link>

              <Link to={`/register`}>
                <Button
                  bg="black"
                  _hover={{
                    bg: "white",
                    color: "black",
                    boxShadow: "0 0 3px black",
                  }}
                  color="#F2F2F2"
                  rounded="40px"
                  py="10px"
                  px="20px"
                  mx="10px"
                  border="1px solid  #fff"
                  fontSize="14px"
                  fontWeight="600"
                >
                  Register
                </Button>
              </Link>
          </Box>
          )}
          {currentUser && (
            <Button
              bg="white"
              _hover={{
                bg: "black",
                color: "white"
              }}
              color="#000"
              rounded="40px"
              py="10px"
              px="20px"
              mx="10px"
              border="1px solid  #fff"
              fontSize="14px"
              fontWeight="600"
              onClick={() => {
                window.localStorage.removeItem("currentUser");
                localStorage.removeItem('sessionRequestFormData');
                localStorage.removeItem('view');
                navigate("/login");
                // window.location.reload();
              }}>
              Log out
            </Button>
          )}
        </Box>
      </Flex>

      <Heading fontSize={{ base: "15px", md: "25px" }}
        fontWeight="400" my="50px" textAlign="center">
        START YOUR CREATIVE JOURNEY WITH
      </Heading>
      <Box display="flex" justifyContent="center" alignItems="center" my="50px">
        <Image
          objectFit={"cover"}
          src={logo}
          alt="Logo"
         width="40%" 
         height="auto"
          backgroundSize="cover"
          crossOrigin="anonymous"
        />
      </Box>

      {/* <Link to="/register">
        <Heading
          fontSize={{ base: "15px", md: "25px" }}
          fontWeight="400"
          textAlign="center"
        >
          Create an account
        </Heading>
      </Link> */}

      <InputGroup borderRadius={5} size="sm" width="50%" margin="auto" mb={10} >
        <Input
          type="text"
          placeholder="Search creative..."
          onChange={handleChange}
          border="1px solid black"
          borderWidth="2px"
          borderColor="gray.300"
          borderRadius="10px"
          padding="20px 20px"
          _hover={{
            borderWidth: "2px",
            borderColor: "gray.300",
          }}
          sx={{ "::placeholder": { color: "grey" } }}
        />
        <InputRightElement
          pointerEvents="none"
          padding="20px 20px"
          fontSize="18px"
          children={<Search2Icon color="#2998FF" />}
        />
      </InputGroup>

      {userData?.map((user) => (
        <Box style={{marginBottom:"20px"}}>
          <CreativeItem key={user._id} data={user} />
        </Box>
      ))}
      {userData.length < 1 && isFecth && <Box textAlign="center">Records not found.</Box>}
    </Box>
  );
}
