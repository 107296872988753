import React,  {useState} from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  useDisclosure,
  Text
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { SERVICE_IMAGE_URL, USER_IMAGE_URL } from "../../../../apiPaths";
import StdProfile from "../../../ProfileDetails";
import {
  BUTTON_BG,
  BUTTON_COLOUR,
} from "../../../../constants/colours";
import AliceCarousel from "react-alice-carousel";

export default function CreativeItem(data) {
  const user = data.data;
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleAvaliablityBtnClick() {
    const element = document.querySelector('.css-hfafpj');
    if (element) {
      element.style.backgroundColor = BUTTON_BG;
      element.style.color = BUTTON_COLOUR;
    }
  }

  const [showDetailModel, setShowDetailModel] = useState(false);

  function handleFormOpen(val) {    
    setShowDetailModel(val);
  }

  function handleFormClose(val) {
    setShowDetailModel(val);
  }

  const settings = {
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 5,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <style>
        {`
        .alice-carousel__stage-item {
          width: 235px !important;
          margin-right: 10px;
        }
        
        .slick-prev { 
          top: 0 !important;
          display:none!important ;
          right: 0 !important;
          left: 93% !important;
          z-index: 1;
        }

        .slick-prev:before {
          font-size: 26px !important;
          color: grey;
          display:none !important;
        }

        .slick-next:before {
          font-size: 26px !important;
          color: grey;
        }
        .slick-next { 
          right: 0 !important;
          z-index: 1;
          height: 86%;
          width: 6%;
          opacity: 0.4;
          background-color: #000000 !important;
        }  
        @media (max-width: 756px) {
          .slick-next {
            font-size: 26px !important;
            color: grey;
            display:none !important;
          }
        }        
        `}
      </style>
      <Box bg="white" rounded="8px" w="90%" m="auto">
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          w="100%"
          gap="24px"
          p="30px 25px">
          <Flex
            flexDirection={{ base: "row", lg: "row" }}
            alignItems={{ base: "start", md: "center" }}
            w="100%">
            <Box flexShrink="0">
              <Image
                height="70px"
                width="70px"
                borderRadius="50%"
                src={USER_IMAGE_URL + user.profilePic}
                alt="Tutor Image"
                crossOrigin="anonymous"
                onClick={() => handleFormOpen(true)}
                cursor="pointer"
              />
            </Box>
            <Box flex="1">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{ base: "column", lg: "row" }}
                gap={{ base: "10px", "2xl": "40px" }}>
                <Flex
                  flexDirection="column"
                  gap="4px"
                  alignItems="flex-start"
                  px="20px"
                  width="80%">
                  <Box fontWeight="600" fontSize="20px">
                    {user?.firstName} {user?.lastName}
                  </Box>
                  <Box fontWeight="500">{user.description}</Box>
                </Flex>
                <Button
                  as={Link}
                  to={`/calendar/tutor/${user._id}`}
                  onClick={handleAvaliablityBtnClick}
                  bg="selectbg"
                  _hover={{
                    bg: "white",
                    color: "black",
                    border: "1px solid black",
                  }}
                  border="1px solid #f2f2f2"
                  backgroundColor="black"
                  rounded="40px"
                  py="20px"
                  px="40px"
                  fontSize="14px"
                  fontWeight="600">
                  View availability
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Flex>

        <Box mx={5} position={"relative"}>
        <AliceCarousel
            mouseTracking = {false}
            items={user?.services?.map((service) => (
              <Box
                key={service._id}
                p={3}
                position="relative"
                boxShadow="lg"
                left={{ base: "0px", md: "0px" }}
                m={2}
                width="100%"
              >
                <Box
                  width={{ base: "100% !important"}}
                >
                  <Image
                    height={180}
                    width="100%"
                    objectFit={"cover"}
                    backgroundSize="cover"
                    src={SERVICE_IMAGE_URL + service.image}
                    alt="Service Image"
                    crossOrigin="anonymous"
                  />
                  <Box fontWeight="600" padding="10px 0">
                    {service.name}
                  </Box>
                </Box>
              </Box>
            ))}
            responsive={{
              0: { items: 1 },
              600: { items: 2 },
              1024: { items: 3 },
            }}
            controlsStrategy="alternate"
            infinite={false}
            disableDotsControls={true}
            renderPrevButton={() => (
              <button type="button" data-role="none" className="slick-arrow slick-prev" style={{ display: "block" }}>
                Previous
              </button>
            )}
            renderNextButton={() => (
              <button type="button" data-role="none" className="slick-arrow slick-next" style={{ display: "block" }}>
                Next
              </button>
            )}
          />

        </Box>
      </Box>
      {showDetailModel && (
        <StdProfile onFormClose={handleFormClose} details={user} />
      )}
    </>
  );
}
