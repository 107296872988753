import React, { useState, useEffect } from 'react';
import { Box } from "@chakra-ui/react";
import RegisterDetails from "../Components/Forms/RegisterPages/RegisterDetail";
import Register_Profile_One from "../Components/Forms/RegisterPages/RegisterProfileOne";
import Register_Profile_Two from "../Components/Forms/RegisterPages/RegisterProfileTwo";
import Register_Profile_Three from '../Components/Forms/RegisterPages/RegisterProfileThree';

const Register = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTutor, setIsTutor] = useState(true);

  const handleNext = (active = false) => {
    if(active) setActiveStep((prevStep) => Math.min(prevStep + 2, steps.length - 1));  
    else setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const handlePrevious = (active=false) => {
    if(active) setActiveStep((prevStep) => Math.max(prevStep - 2, 0));
   else setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const steps = [
    {
      content: (
        <RegisterDetails
        isLoading={isLoading} 
        setIsLoading={setIsLoading} 
        setFormData={setFormData}
        handleNext={handleNext}
        handlePrevious={handlePrevious} 
        setIsTutor={setIsTutor}
        />
      ),
    },
    {
      content: (
        <Register_Profile_One
        isLoading={isLoading} 
        setIsLoading={setIsLoading} 
        setFormData={setFormData}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        activeStep={activeStep}
        isTutor={isTutor}
        />
      ),
    },
    {
      content: (
        <Register_Profile_Two
        isLoading={isLoading} 
        setIsLoading={setIsLoading} 
        setFormData={setFormData}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        />
      ),
    },
    {
      content: (
        <Register_Profile_Three
          isLoading={isLoading} 
          setIsLoading={setIsLoading} 
          setFormData={setFormData}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          formData={formData}
          activeStep={activeStep}
          isTutor={isTutor}
        />
      ),
    },
  ];

  return <Box>{steps[activeStep].content}</Box>;
};

export default Register;
