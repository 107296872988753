import React, { useState, useEffect } from "react";
import {
  Stack,
  Button,
  VStack,
  Center,
  Box,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useFieldArray, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import SessionHeader from "../../../Headers/SessionHeader";
import AddServiceQuestion from "./AddServiceQuestion";
import { API_BASE_URL } from "../../../../apiPaths";

const NewServiceTwo = ({
  handleNext,
  handlePrevious,
  setFormData,
  formData,
  isEnoughServices,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });

  const [isSaveMode, setIsSaveMode] = useState(true); // Track button mode
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  // Check if all required fields are filled
  useEffect(() => {
    const subscription = watch((_, { name }) => {
      const allFieldsFilled = fields.every((_, index) => {
        const questionValue = watch(`fields[${index}].question`);
        const answerTypeValue = watch(`fields[${index}].answerType`);
        const optionsValue = watch(`fields[${index}].options`);

        return questionValue && questionValue.trim() !== "" &&
               answerTypeValue &&
               (answerTypeValue !== "multipleChoice" || (optionsValue && optionsValue.trim() !== ""));
      });

      setIsSaveButtonDisabled(!allFieldsFilled);
    });

    return () => subscription.unsubscribe();
  }, [fields, watch]);

  useEffect(() => {
    if (fields.length === 0) {
      append({ question: "" });
    }
  }, [append]);

  useEffect(() => {
    const savedData = formData.questionsAnswers;

    if (savedData) {
      savedData.forEach((item, index) => {
        setValue(`fields[${index}].question`, item.question);
        setValue(`fields[${index}].answerType`, item.answerType);
        setValue(`fields[${index}].options`, item.options);
      });
    }
  }, [setValue, formData.questionsAnswers]);

  const handleSaveQuestionClick = () => {
    // Save the questions data
    const formData = getValues();
    setFormData((prevData) => ({
      ...prevData,
      questionsAnswers: formData.fields || [],
    }));
    setIsSaveButtonDisabled(true);
    setIsSaveMode(false); // Switch to "Continue" mode
  };

  const handleContinueClick = () => {
   if(isSaveMode) {
    setFormData((prevData) => ({
      ...prevData,
      questionsAnswers: [],
    }));
   }
    // Move to the next step
    handleNext();
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/service-builder/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentUser.token,
        },
      });

      if (!response.ok) {
        toast({
          title: "Error deleting service.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error("Network response was not ok");
      } else {
        toast({
          title: "Service deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/yourservices");
      }
    } catch (error) {
      console.error("Error deleting service:", error);
    }
  };

  return (
    <Box>
      <SessionHeader handleNext={handleNext} handlePrevious={handlePrevious} />
      <Center mt={{ base: "10px", xl: "20px" }}>
        <VStack
          as={"form"}
          style={{ width: "100%", margin: "0 auto" }}
        >
          <Stack
            minW={{ base: "100%", md: "640px" }}
            maxW={{ base: "100%", md: "640px" }}
            spacing={{ base: 6, xl: 4 }}
            px={{ base: "15px", md: "0px" }}
            py={{ base: "16px", md: "0px" }}
          >
            {(fields.length === 0 || fields[0].question === "") && (
              <Text textAlign="center" paddingY="15px">
                Add questions you wish to ask your client
              </Text>
            )}
            <VStack w="100%">
              <VStack w="100%" spacing={4}>
                {fields.map((item, index) => (
                  <AddServiceQuestion
                    key={item.id}
                    register={register}
                    control={control}
                    index={index}
                    errors={errors}
                    watch={watch}
                    remove={remove}
                    handleAddQuestionClick={() => {
                      append({ question: "" });
                    }}
                    isLastQuestion={index === fields.length - 1}
                  />
                ))}
              </VStack>
            </VStack>
            <VStack color="black">  
              <VStack spacing={{ base: "15px", md: "20px" }} w="100%">
                <Button
                  mt="15px"
                  color={isSaveMode ? "black" : "white"}
                  _hover={{
                    bg: isSaveMode ? "black" : "black",
                    color: "white",
                  }}
                  rounded="40px"
                  w="100%"
                  bg={isSaveMode ? "graybtn" : "black"}
                  py="12px"
                  fontSize="14px"
                  fontWeight="600"
                  isDisabled={isSaveButtonDisabled}
                  onClick={handleSaveQuestionClick}
                  opacity={isSaveButtonDisabled ? 0.6 : 1}
                >
                  Save Question
                </Button>
              </VStack>

              <VStack w="100%" mt={{ base: "15px", md: "20px" }}>
                <Button
                  color="black"
                  rounded="40px"
                  _hover={{
                    bg: "black",
                    color: "white",
                  }}
                  w="100%"
                  bg={"graybtn"}
                  py="12px"
                  fontSize="14px"
                  fontWeight="600"
                  type="submit"
                  onClick={handleContinueClick}
                >
                  {isSaveMode ? " Skip adding questions" : "Continue"}
                </Button>
                <Box marginTop="50px" width="100%">
                  {formData?.isService && isEnoughServices && (
                    <Button
                      bg="#E1526C"
                      color="white"
                      fontWeight="bold"
                      rounded="20px"
                      w="full"
                      onClick={() => handleDelete(formData?._id)}
                      _hover={{
                        color: "white",
                        bg: "#E1526C",
                      }}
                    >
                      Delete Service
                    </Button>
                  )}
                  <Button
                    mt="30px"
                    color="black"
                    _hover={{
                      bg: "black",
                      color: "white",
                    }}
                    rounded="40px"
                    w="100%"
                    bg="graybtn"
                    py="12px"
                    fontSize="14px"
                    fontWeight="600"
                    as={Link}
                    to="/yourservices"
                  >
                    Cancel
                  </Button>
                </Box>
              </VStack>
            </VStack>
          </Stack>
        </VStack>
      </Center>
    </Box>
  );
};

export default NewServiceTwo;
