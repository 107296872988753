import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CreativeItem from "../Components/FromSidebar/Student/CreativesList/CreativeItem";
import CreativesListHeader from "../Components/Headers/CreativesListHeader";
import { API_BASE_URL } from "../apiPaths";

export default function CreativesList() {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        const response = await fetch(`${API_BASE_URL}/user/tutors/get?q=`, {
          headers: {
            "x-access-token": currentUser.token,
          },
        });
        const users = await response.json();
        setUserData(users.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <CreativesListHeader />
      <Flex
        flexDirection="column"
        mt={{ base: "20px", md: "40px" }}
        gap="24px"
        maxW={{ base: "90%"
        , md: "90%" }}
        mx="auto">
        {userData?.map((user) => (
          <CreativeItem key={user._id} data={user} />
        ))}
      </Flex>
    </>
  );
}
