import React, { useEffect, useState } from "react";
import {
  FormControl,
  Input,
  Button,
  Box,
  FormLabel,
  Select,
  FormErrorMessage,
  useToast,
  Modal,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_BASE_URL } from "../../../apiPaths";

const signupSchema = yup.object().shape({
  start: yup.date().required("Start time is required"),
  end: yup
    .date()
    .required("End time is required")
    .min(yup.ref("start"), "End time can't be before start time"),
  service: yup.string().required("Service is required"),
  location: yup.string().required("Location is required"),
});

const EditBooking = ({
  onFormClose,
  fetchBookings,
  bookingId,
  onDeleteSuccess,
  selectedBooking,
  tutorId
}) => {
  
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const toast = useToast();
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(true);
  const { isOpen, onClose } = useDisclosure();
  const currentDateTime = new Date().toISOString().slice(0, 16);

  const formatDateTimeLocal = (date) => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().slice(0, 16);
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signupSchema),
    defaultValues: {},
  });

  const sessionLocationOptions = [
    { value: "Home Studio", label: "Home Studio" },
    { value: "Zoom Meeting", label: "Zoom Meeting" },
    // Add more options as needed
  ];

  useEffect(() => {    
    const fetchMyServices = async () => {
      if (bookingId && tutorId) {
        try {
          const response = await fetch(
            `${API_BASE_URL}/service-builder/${tutorId}`, 
            {
              headers: {
                "x-access-token": currentUser.token,
              },
            }
          );
          const result = await response.json();
          setServices(result.data);
        } catch (error) {
          toast({
            title: "Error fetching booking details.",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          populateForm(selectedBooking)
        }
      }
    }
    populateForm(selectedBooking)
    fetchMyServices();
  }, [bookingId, selectedBooking]);

  function handleClose() {
    onFormClose(false);
  }

  // Populate form with fetched booking data
  const populateForm = (data) => {
    let start, end;
    if(data.isCalendarBooking) {
      start = new Date(data.start)
      end = new Date(data.end)
    } else {
      start = new Date(data.sessionId.start)
      end = new Date(data.sessionId.end)
    }
    setValue("start", formatDateTimeLocal(start));
    setValue("end", formatDateTimeLocal(end));
    setValue("service", data.serviceId?._id);
    setValue("location", data.location || "Home Studio");
  };

  const handleStartChange = (value) => {
    const startDate = new Date(value);
    const endDate = new Date(startDate.getTime() + 2 * 60 * 60 * 1000);
    setValue("end", formatDateTimeLocal(endDate));
  };

  const onSubmit = async (data) => {   
    if(selectedBooking.isCalendarBooking) data.isCalendarBooking = true;
    else data.isCalendarBooking = false;
    try {
      const response = await fetch(`${API_BASE_URL}/booking/${bookingId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentUser.token,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast({
          title: "Booking request send successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        fetchBookings(); 
        onFormClose(false); 
      } else {
        const responseData = await response.json();
        toast({
          title: responseData.data.error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error updating booking.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/booking/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentUser.token,
        },
      });

      if (!response.ok) {
        toast({
          title: "Error deleting booking.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error("Network response was not ok");
      } else {
        onDeleteSuccess();
        toast({
          title: "Booking deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleClose();
      }
    } catch (error) {
      console.error("Error deleting booking:", error);
    }
  };

    return (
      <>
        <Box>
          <Modal
            sx={{ position: "fixed", left: "130px" }}
            blockScrollOnMount={false}
            isOpen={open}
            isCentered
            onClose={onClose}
            size={{
              base: "md",
              md: "xl",
              lg: "2xl",
              xl: "2xl",
              "2xl": "2xl",
            }}
          >
            <ModalContent bg={"#fff"} height={{ base: "auto", sm: "auto" }}>
              <ModalCloseButton
                position="absolute"
                top="10px"
                left="10px"
                color="black"
                onClick={() => {
                  handleClose();
                }}
              />
              <ModalHeader
                textAlign="center"
                fontSize="24px"
                fontWeight="600"
                color="black"
                marginTop="20px"
              >
                Booking Request
              </ModalHeader>
  
              <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "20px" }}>
                <FormControl mt="4" isInvalid={errors.start}>
                  <FormLabel color="black">Start</FormLabel>
                  <Controller
                    name="start"
                    control={control}
                    rules={{
                      validate: (value) => {
                        const selectedTime = new Date(value).getHours();
                        const selectedDay = new Date(value).getDay();
  
                        // Load work hours from local storage
                        const workHours = JSON.parse(
                          localStorage.getItem("workHours")
                        ) || {
                          monFri: { start: 9, end: 17 },
                          sat: { start: 9, end: 17 },
                          sun: { start: 9, end: 17 },
                        };
  
                        let workingHours;
                        if (selectedDay >= 1 && selectedDay <= 5) {
                          // It's a weekday
                          workingHours = workHours.monFri;
                        } else if (selectedDay === 6) {
                          // It's Saturday
                          workingHours = workHours.sat;
                        } else {
                          // It's Sunday
                          workingHours = workHours.sun;
                        }
  
                        if (
                          selectedTime < workingHours.start ||
                          selectedTime > workingHours.end
                        ) {
                          return `Please select a time between ${workingHours.start}:00 and ${workingHours.end}:00`;
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        type="datetime-local"
                        borderWidth="1px"
                        borderColor="gray.300"
                        _hover={{
                          borderWidth: "1px",
                          borderColor: "gray.300",
                        }}
                        color="black"
                        sx={{
                          "::-webkit-calendar-picker-indicator": {
                            filter: "invert(1)", // Inverts the colors of the calendar icon
                            backgroundColor: "transparent", // Avoids a white square around the icon
                          },
                        }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          handleStartChange(e.target.value);
                        }}
                        min={currentDateTime}
                      />
                    )}
                  />
                  <FormErrorMessage>{errors.start?.message}</FormErrorMessage>
                </FormControl>
  
                <FormControl mt="4" isInvalid={errors.end}>
                  <FormLabel color="black">End</FormLabel>
                  <Controller
                    name="end"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="datetime-local"
                        borderWidth="1px"
                        borderColor="gray.300"
                        _hover={{
                          borderWidth: "1px",
                          borderColor: "gray.300",
                        }}
                        color="black"
                        sx={{
                          "::-webkit-calendar-picker-indicator": {
                            filter: "invert(1)", // Inverts the colors of the calendar icon
                            backgroundColor: "transparent", // Avoids a white square around the icon
                          },
                        }}
                        {...field}
                        min={currentDateTime}
                      />
                    )}
                  />
                  <FormErrorMessage>{errors.end?.message}</FormErrorMessage>
                </FormControl>
  
                <FormControl mt="4" isInvalid={errors.service}>
                  <FormLabel color="black">Specify a Service</FormLabel>
                  <Controller
                    control={control}
                    name="service"
                    render={({ field }) => (
                      <Select
                        {...field}
                        borderWidth="1px"
                        borderColor="gray.300"
                        _hover={{
                          borderWidth: "1px",
                          borderColor: "gray.300",
                        }}
                        color="black"
                      >
                        {services?.map((service) => (
                          <option
                          style={{ backgroundColor: "white", color: "black" }}
                          key={service._id}
                          value={service._id}
                        >
                          {service.name}
                        </option>
                        ))}
                      </Select>
                    )}
                  />
                  <FormErrorMessage>{errors.service?.message}</FormErrorMessage>
                </FormControl>
  
                <FormControl mt="4" isInvalid={errors.location}>
                  <FormLabel color="black">Specify a Location</FormLabel>
                  <Controller
                    control={control}
                    name="location"
                    render={({ field }) => (
                      <Select
                        {...field}
                        borderWidth="1px"
                        borderColor="gray.300"
                        _hover={{
                          borderWidth: "1px",
                          borderColor: "gray.300",
                        }}
                        color="black"
                      >
                        {sessionLocationOptions.map((option) => (
                          <option
                            style={{ backgroundColor: "white", color: "black" }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                  <FormErrorMessage>{errors.location?.message}</FormErrorMessage>
                </FormControl>
  
                <>
                      <Button
                        bg="black"
                        color="white"
                        fontWeight="bold"
                        rounded="20px"
                        w="full"
                        mt="8"
                        _hover={{
                          border: "1px solid black",
                          color: "black",
                          bg: "white",
                        }}
                        type="submit"
                      >
                        Send Booking Request
                      </Button>
                      <Button
                        type="button"
                        bg="red.500"
                        color="white"
                        fontWeight="bold"
                        rounded="20px"
                        w="full"
                        mt="4"
                        _hover={{
                          border: "1px solid #E53E3E",
                          color: "red.500",
                          bg: "white",
                        }}
                        onClick={() => handleDelete(bookingId)}
                      >
                        Delete Booking
                      </Button>
                    </>
              </form>
            </ModalContent>
          </Modal>
        </Box>
      </>
  );
};

export default EditBooking;
