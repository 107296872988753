import { Box } from "@chakra-ui/react";
import React from "react";
import BookSessionFormHeader from "../Components/Headers/BookSessionFormHeaderTwo";
import BookSessionFormTwo from "../Components/Forms/BookSession/BookSessionFormTwo";

export default function BookSessionsForm() {
  return (
    <>
      <BookSessionFormTwo />
    </>
  );
}
