import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FiMenu } from "react-icons/fi";
import { LuPlus } from "react-icons/lu";
import { LuChevronLeft } from "react-icons/lu";
import { Link } from "react-router-dom";

export default function SessionHeader({ handleNext, handlePrevious, formOne}) {
  const onBack = (data) => {
    handlePrevious();
  };

  return (
    <>
      <Box
        w="full"
        px="24px"
        bg={{ base: "#1D1D1D", md: "white" }}
        color="black"
        py={{ base: "0px", md: "34px" }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap="5px"
          flexDir="row"
          display={{ base: "none", md: "flex" }}
          position="relative"
        >
          { !formOne && (
           <Link>
            <Button
              leftIcon={<LuChevronLeft />}
              color="black"
              py="6px"
              onClick={onBack}
            >
              Back
            </Button>
          </Link>
          )}
          <Box 
            margin="auto"
            textAlign="center"
            position="absolute"
            left="50%"
            transform="translateX(-50%)"
          >
            <Heading color="black" fontWeight="600" fontSize="28px">
              Services
            </Heading>
            <Text color="text" fontWeight="500" fontSize="14px">
              Manage and Create the services you offer
            </Text>
          </Box>
          <Box marginLeft="auto">
            <Button
              as={Link}
              to="/yourservices/new"
              rightIcon={<LuPlus />}
                  bg="black"
                  _hover={{
                    bg: "white",
                    color: "black",
                    boxShadow: "0 0 3px black",
                  }}
                  color="#F2F2F2"
                  rounded="40px"
                  py="12px"
                  px="24px"
                  w={{ base: "100%", xl: "auto" }}
                  fontSize="14px"
                  fontWeight="600"
                  alignItems="end"
                >
                  Create a Service
            </Button>
          </Box>
        </Flex>
      </Box>
    </>
  );
}
