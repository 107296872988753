import React from "react";
import {
  FormControl,
  Input,
  Select,
  FormErrorMessage,
  VStack,
  FormLabel,
  Box,
  Flex,
} from "@chakra-ui/react";

export default function AddServiceQuestion({
  register,
  control,
  watch,
  index,
  remove,
  errors,
  handleAddQuestionClick,
  isLastQuestion,
}) {
  const questionName = `fields[${index}].question`;
  const answerTypeName = `fields[${index}].answerType`;
  const optionsName = `fields[${index}].options`;
  const getErrorMessage = (fieldName) => {
    return errors?.fields?.[index]?.[fieldName]?.message;
  };

  const questionValue = watch(questionName);
  const answerTypeValue = watch(answerTypeName);
  const optionsValue = watch(optionsName);

  const isQuestionFilled = questionValue && questionValue.trim() !== "";
  const isAnswerTypeFilled = answerTypeValue;
  const isOptionsFilled = answerTypeValue !== "multipleChoice" || (answerTypeValue === "multipleChoice" && optionsValue && optionsValue.trim() !== "");

  const isAddQuestionButtonDisabled = !isQuestionFilled || !isAnswerTypeFilled || !isOptionsFilled;

  return (
    <VStack
      w="100%"
      px={{ base: "15px", xl: "32px" }}
      py={{ base: "12px", xl: "24px" }}
      bg="white"
      rounded="8px"
      spacing={4}
      alignItems="normal"
    >
      <Box textAlign="left" fontSize="14px" fontWeight="500">
        Question {index + 1}
      </Box>
      <FormControl isInvalid={!!getErrorMessage("question")}>
        <Input
          borderColor={"border"}
          rounded="md"
          type="text"
          _placeholder={{
            color: "text",
            fontSize: { base: "12px", md: "14px" },
          }}
          placeholder="Type question here"
          {...register(questionName, {
            required: {
              value: index > 0,
              message: "Question is required ",
            },
          })}
        />

        <FormErrorMessage>{getErrorMessage("question")}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!getErrorMessage("answerType")}>
        {/* <Select
          {...register(answerTypeName)}
          borderColor={"border"}
          rounded="md"
          type="text"
          bg="selectbg"
        >
          <option style={{ color: "#616161", fontSize: "14px" }} value="text">
            Text
          </option>
          <option value="multipleChoice">Multiple Choice</option>
        </Select> */}
        <Select
          borderColor={"border"}
          rounded="md"
          type="text"
          bg="selectbg"
          defaultValue={["Beginner"]}
          {...register(answerTypeName)}
        >
          <option style={{ color: "#616161", fontSize: "14px" }} value="text">
            Text
          </option>
          <option value="multipleChoice">Multiple Choice</option>
        </Select>
        <FormErrorMessage>{getErrorMessage("answerType")}</FormErrorMessage>
      </FormControl>

      {watch(answerTypeName) === "multipleChoice" && (
        <>
        <FormControl isInvalid={!!getErrorMessage("options")}>
          <FormLabel fontSize="14px" textAlign="left">
            Options
          </FormLabel>
          <Input
            borderColor={"border"}
            rounded="md"
            type="text"
            _placeholder={{
              color: "text",
              fontSize: { base: "12px", md: "14px" },
            }}
            placeholder="Separate their answer options with a comma like this: option1, option2, option3"
            {...register(optionsName, {
                required:
                  watch(answerTypeName) === "multipleChoice" &&
                  "Options are required for multiple choice",
            })}
          />
          <FormErrorMessage>{getErrorMessage("options")}</FormErrorMessage>
        </FormControl>
        </>
      )}
      <Flex justifyContent="space-between" gap="4" alignItems="center">
        {isLastQuestion && (
          <Box
            width="100%"
            textAlign="left"
            fontSize="18px"
            fontWeight="500"
            marginTop="10px"
            onClick={!isAddQuestionButtonDisabled ? handleAddQuestionClick : undefined}
            cursor={!isAddQuestionButtonDisabled ? "pointer" : "not-allowed"}
            color={!isAddQuestionButtonDisabled ? "black.500" : "gray.400"}
          >
            Another Question
          </Box>
        )}
        <Box color="red.400" width="100%" textAlign="right" onClick={() => remove(index)} cursor="pointer">
          Remove Question
        </Box>
      </Flex>
    </VStack>
  );
}
