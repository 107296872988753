import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Button,
} from "@chakra-ui/react";

import { USER_IMAGE_URL, SERVICE_IMAGE_URL, API_BASE_URL } from "../apiPaths";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";

const ProfileDetails = ({ onFormClose, details, user }) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [open, setOpen] = useState(true);
  const { isOpen, onClose } = useDisclosure();
  const [myServices, setMyServices] = useState([]);

  function handleClose() {
    setOpen(false); // Close modal on button click
    onFormClose(false);
  }

  const settings = {
    centerPadding: "10px",
    slidesToShow: 5,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    async function getTutorServices() {
      try {
        const serviceResponse = await fetch(
          `${API_BASE_URL}/service-builder/${details._id}`,
          {
            headers: {
              "x-access-token": currentUser.token,
            },
          }
        );
        const services = await serviceResponse.json();

        setMyServices(services.data); // Ensure `services.data` is the correct array
      } catch (error) {
        console.error("Failed to fetch services:", error);
      }
    }

    if (currentUser.roleName === "STUDENT") {
      getTutorServices();
    }
  }, [currentUser.roleName, details._id]);

  return (
    <Box>
      <style>
        {`
        .slick-track {
          display: flex;
        }
        .slick-slide {
          padding: 0 5px; 
          width: 230px !important;
        }
        .slick-next { 
          top: 46% !important;
          right: 1% !important;
          z-index: 1000 !important;
        }

        .slick-prev { 
          display: none !important;
        }

        .slick-next:before {
          font-size: 26px !important;
          color: grey;
        }

        .slick-next { 
          right: 0 !important;
          z-index: 1;
          height: 80%;
          width: 8.6%;
          opacity: 0.4;
          background-color: #000000 !important;
        }  

        // @media (max-width: 756px) {
        //   .slick-next {
        //     display: none !important;
        //   }
        // }        
        `}
      </style>
      <Modal
        sx={{ position: "fixed", left: "130px" }}
        blockScrollOnMount={false}
        isOpen={open}
        isCentered
        onClose={onClose}
        size={{
          base: "md",
          md: "xl",
          lg: "2xl",
          xl: "2xl",
          "2xl": "2xl",
        }}
      >
        <ModalOverlay />
        <ModalContent
          bg="white"
          alignItems="center"
          color="black"
          padding="2% 0"
        >
          <ModalCloseButton
            position="absolute"
            top="10px"
            left="10px"
            color="black"
            onClick={handleClose}
          />
          <ModalHeader textAlign="center">
            <Image
              height="80px"
              width="80px"
              borderRadius="50%"
              crossOrigin="anonymous"
              src={USER_IMAGE_URL + details?.profilePic}
              alt="User Profile Image"
              cursor="pointer"
            />
          </ModalHeader>
          <ModalBody width="700px">
            <Box padding="0 6%">
              <Box
                fontSize="20px"
                fontWeight="600"
                marginTop="15px"
                textAlign="center"
              >
                {details?.firstName} {details?.lastName}
              </Box>
              <Box fontWeight="500" textAlign="center" paddingTop="20px">
                {details?.description}
              </Box>
            </Box>
              {myServices.length > 0 && (
                <Box marginTop="20px">
                  <Box mx={5} position={"relative"} m="auto">

                  <AliceCarousel
                    mouseTracking= {false}
                    items={myServices.map((service) => (
                      <Box
                        key={service.id}
                        p={3}
                        position="relative"
                        boxShadow="lg"
                        left={{ base: "0px", md: "0px" }}
                        m={4}
                      >
                        <Box
                          width={{
                            base: "100% !important",
                          }}
                        >
                          <Image
                            height={180}
                            width="100%"
                            objectFit={"cover"}
                            backgroundSize="cover"
                            src={SERVICE_IMAGE_URL + service.image}
                            alt="Service Image"
                            crossOrigin="anonymous"
                          />
                          <Box
                            fontWeight="600"
                            padding="10px 0"
                            color="black"
                          >
                            {service.name}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                    responsive={{
                      0: { items: 1 },
                      600: { items: 2 },
                      1024: { items: 3 },
                    }}
                    controlsStrategy="alternate"
                    infinite={false}
                    disableDotsControls={true}
                    renderPrevButton={() => (
                      <button type="button" data-role="none" className="slick-arrow slick-prev" style={{ display: "block" }}>
                        Previous
                      </button>
                    )}
                    renderNextButton={() => (
                      <button type="button" data-role="none" className="slick-arrow slick-next" style={{ display: "block" }}>
                        Next
                      </button>
                    )}
                  />

                  </Box>
                  <Button
                    as={Link}
                    to={`/calendar/tutor/${details?._id}`}
                    bg="selectbg"
                    _hover={{
                      bg: "white",
                      color: "black",
                      border: "1px solid black",
                    }}
                    border="1px solid #f2f2f2"
                    backgroundColor="black"
                    rounded="40px"
                    my="20px"
                    py="20px"
                    px="40px"
                    mx="35%"
                    fontSize="14px"
                    fontWeight="600"
                  >
                    View availability
                  </Button>
                </Box>
              )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProfileDetails;
