import React from "react";
import { useState, useEffect } from "react";
import {
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  Switch,
  InputGroup,
  Box,
  Textarea,
  FormLabel,
  Image,
  Flex,
  Select,
  FormErrorMessage,
  useToast
} from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const Register_Profile_Two = ({ handleNext, handlePrevious, isLoading, setIsLoading, setFormData }) => {
  const toast = useToast();
  const [show, setShow] = useState(false);

  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const handleSwitchChange = (e) => {
    setIsSwitchOn(e.target.checked);
  };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue
  } = useForm();

  const onSubmit = async (data) => {    
    setIsLoading(true);
    setFormData(prevData => ({ ...prevData, ...data }));
    setIsLoading(false);
    handleNext();
  };

  return (
    <Box position="relative" bg="black" h="100vh" w="100vw">
      <Box
        display={{ base: "none", md: "block" }}
        position="absolute"
        top="5%"
        left="10%"
        fontSize="48px"
        color="#2998FF"
        fontWeight="600"
      >
        Logo
      </Box>


      <Center h="100vh" alignItems={["end", "end", "center"]}>
        <Stack minW={{ base: "100%", md: "640px" }} spacing={12}>
          <Center
            display={{ base: "flex", md: "none" }}
            fontSize="24px"
            color="#2998FF"
            fontWeight="600"
          >
            Logo
          </Center>

          <VStack
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            bg="#1D1D1D"
            position="relative"
            borderTopRadius={{ base: "16px", md: "none" }}
            rounded={{ base: "", md: "lg" }}
            px={{ base: "15px", md: "120px" }}
            py={{ base: "40px", md: "60px" }}
          >
            <Box
              onClick={() => {
                handlePrevious();
              }}
              position="absolute"
              top="25px"
              left="24px"
              color="#D2D2D2"
              fontWeight="500"
              display="flex"
              gap="5px"
              alignItems="center"
              cursor="pointer"
            >
              <IoIosArrowBack size="20px" />
              <Box>Back</Box>
            </Box>

            <Stack
              align="center"
              mb={{ base: "15px", md: "30px" }}
              mt={{ base: "20px", md: "10px" }}
            >
              <Heading
                fontSize={{ base: "18px", md: "24px" }}
                fontWeight="600"
                color="white"
              >
                Create an account
              </Heading>
              <Box color="#D2D2D2" fontSize={{ base: "18px", md: "18px" }}>
                Your Profile 2/3
              </Box>
            </Stack>
            <VStack spacing="12px" w="100%">
              <FormControl mt="4" isInvalid={errors.earliestStartTime}>
                <FormLabel color="#D2D2D2">Your Calendar Day Starts From</FormLabel>

                <Input
                  type="time"
                  {...register("earliestStartTime")}
                  defaultValue={watch("earliestStartTime") || "09:00"}
                  bg="#2F2F2F"
                  borderColor={errors.earliestStartTime ? "#E1526C" : "#616161"}
                  rounded="md"
                  _hover={{
                    borderColor: "#616161",
                  }}
                  color="white"
                />
                <FormErrorMessage>
                  {errors.earliestStartTime?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl mt={4} isInvalid={errors.latestFinishTime}>
                <FormLabel color="#D2D2D2">Your Calendar Day Finishes At</FormLabel>
                <Input
                  type="time"
                  {...register("latestFinishTime")}
                  defaultValue={watch("latestFinishTime") || "17:00"}
                  bg="#2F2F2F"
                  borderColor={errors.latestFinishTime ? "#E1526C" : "#616161"}
                  rounded="md"
                  _hover={{
                    borderColor: "#616161",
                  }}
                  color="white"
                />
                <FormErrorMessage>
                  {errors.latestFinishTime?.message}
                </FormErrorMessage>
              </FormControl>

              <VStack>
              <Heading
                fontSize={{ base: "18px", md: "24px" }}
                fontWeight="600"
                color="white"
                mt="20px"
              >
                Rate Setting
              </Heading>
              </VStack>

              <VStack spacing={{ base: "15px", md: "20px" }} w="100%">
                {/* <FormControl mt={4}>
                  <FormLabel color="#D2D2D2">Flat Rate</FormLabel>
                  <Switch
                    id='rate-setting'
                    size='lg'
                    onChange={handleSwitchChange}
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        color: 'grey',
                        '&.Mui-checked': {
                          color: 'blue ',
                        },
                      },
                    }}
                  />
                </FormControl> */}

                {/* {!isSwitchOn && ( */}
                  <FormControl mt="4">
                    <FormLabel color="#D2D2D2">Your flat rate per hour</FormLabel>
                    <InputGroup gap="2" color="#D2D2D2">
                      <span className="euro-sign">£</span>
                      <Input
                      {...register("flatRate")}
                        defaultValue={watch("flatRate") || "0.0"}
                        className="rate-per-hour"
                        type='text'
                        borderWidth='1px'
                        borderColor='gray.300'
                        _hover={{
                          borderWidth: '1px',
                          borderColor: 'gray.300',
                        }}
                      />
                    </InputGroup>
                  </FormControl>
                {/* )} */}

                {/* {isSwitchOn && (
                  <>
                    <FormControl mt="4">
                      <FormLabel>Your rate for sessions under 1 hour</FormLabel>
                      <InputGroup gap="2">
                        <span className="euro-sign">£</span>
                        <Input
                          type="number"
                          borderWidth="1px"
                          borderColor="gray.300"
                          placeholder="per hour"
                          _placeholder={{
                            color: "text",
                            fontSize: { base: "12px", md: "14px" },
                          }}
                          _hover={{
                            borderWidth: "1px",
                            borderColor: "gray.300",
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl mt="4">
                      <FormLabel>Your rate for sessions 1 hour and over</FormLabel>
                      <InputGroup gap="2">
                        <span className="euro-sign">£</span>
                        <Input
                          type="number"
                          borderWidth="1px"
                          borderColor="gray.300"
                          placeholder="per hour"
                          _placeholder={{
                            color: "text",
                            fontSize: { base: "12px", md: "14px" },
                          }}
                          _hover={{
                            borderWidth: "1px",
                            borderColor: "gray.300",
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl mt="4">
                      <FormLabel>Your rate for sessions 2 hours and over</FormLabel>
                      <InputGroup gap="2">
                        <span className="euro-sign">£</span>
                        <Input
                          type="number"
                          borderWidth="1px"
                          borderColor="gray.300"
                          placeholder="per hour"
                          _placeholder={{
                            color: "text",
                            fontSize: { base: "12px", md: "14px" },
                          }}
                          _hover={{
                            borderWidth: "1px",
                            borderColor: "gray.300",
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl mt="4">
                      <FormLabel>Your rate for sessions 3 hours and over</FormLabel>
                      <InputGroup gap="2">
                        <span className="euro-sign">£</span>
                        <Input
                          type="number"
                          borderWidth="1px"
                          borderColor="gray.300"
                          placeholder="per hour"
                          _placeholder={{
                            color: "text",
                            fontSize: { base: "12px", md: "14px" },
                          }}
                          _hover={{
                            borderWidth: "1px",
                            borderColor: "gray.300",
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl mt="4">
                      <FormLabel>Your rate for sessions 4 hours and over</FormLabel>
                      <InputGroup gap="2">
                        <span className="euro-sign">£</span>
                        <Input
                          type="number"
                          borderWidth="1px"
                          borderColor="gray.300"
                          placeholder="per hour"
                          _placeholder={{
                            color: "text",
                            fontSize: { base: "12px", md: "14px" },
                          }}
                          _hover={{
                            borderWidth: "1px",
                            borderColor: "gray.300",
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                  </>
                )} */}
              </VStack>
            </VStack>
            <VStack w="100%" mt={{ base: "15px", md: "30px" }}>
              <Button
                color="white"
                rounded="40px"
                w="100%"
                py="12px"
                fontSize="14px"
                fontWeight="600"
                bg={"black"}

                type="submit"
                isLoading={isLoading}
              >
                Continue
              </Button>
            </VStack>
          </VStack>
        </Stack>
      </Center>
    </Box>
  );
};


export default Register_Profile_Two;
