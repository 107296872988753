import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Text,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import BookSessionFormThree from "../Forms/BookSession/BookSessionFormThree";
import MyCalendar from "../Calender/StdCalendar/Calender";

export default function PaymentModel({
  handleNext,
  handlePrevious,
  data,
  setShowBooked1,
  onClose,
}) {
  const startDate = new Date(data?.start);
  const [showBooked, setShowBooked] = useState(false);
  const [open, setOpen] = useState(true);
  const endDate = new Date(data?.end);
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = startDate.toLocaleDateString("en-US", options);
  const startTime = startDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const endTime = endDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedTime = `${startTime} - ${endTime}`;
  const totalHours = Math.abs(endDate - startDate) / 36e5;
  const totalPayment = totalHours * data?.serviceId?.rate;

  const handleFormChange = () => {
    setShowBooked(true);
  };
  if (showBooked) {
    return (
      <>
        <BookSessionFormThree data={data} />
      </>
    );
  }
  const handleback = () => {
    setShowBooked1(false);
  };
  return (
    <Box>
        <MyCalendar/>
      <Modal
        blockScrollOnMount={false}
        isOpen={open}
        isCentered
        onClose={onClose}
        size={{
          base: "lg",
          sm: "lg",
          md: "xl",
          lg: "2xl",
          xl: "2xl",
          "2xl": "3xl",
        }}>
      
        <ModalContent bg={"#fff"} p={4} height={{ base: "auto", sm: "auto" }}>
          <Flex
            justifyContent={"center"}
            position={"relative"}
            marginTop="10px">
            <Box
              position={"absolute"}
              left={{ base: "10px", sm: "50px" }}
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}>
              <Box color={"#000"} mt={2}>
                <ChevronLeftIcon fontSize={"30px"} />
              </Box>
              <Button onClick={() => handleback()} p="0">
                <Text mt={2} color="#000" fontWeight="500" fontSize="16px">
                  Back
                </Text>
              </Button>
            </Box>
            <Box>
              <Flex flexDirection="column" alignItems="center">
                <Text
                  mt={{ base: "50px", sm: "3px" }}
                  fontSize={"24px"}
                  color="#000"
                  marginTop="20px"
                  alignItems="center"
                  fontWeight="600">
                  Payment
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            my={7}
            rounded="8px">
            <Box
              width={{ base: "85%", sm: "80%" }}
              display="flex"
              justifyContent={"center"}
              flexDirection={"column"}>
              <Text color="#000" fontSize="14px" fontWeight="700" my={1}>
                Details
              </Text>
              <Text color="#000" fontSize="14px" fontWeight="500" my={1}>
                {data?.userId?.firstName} {data?.userId?.lastName}
              </Text>
              <Text color="#000" fontSize="14px" fontWeight="500" my={1}>
                {data?.serviceId?.name}
              </Text>
              <Text color="#000" fontSize="14px" fontWeight="500" my={1}>
                {formattedDate}, {formattedTime}
              </Text>

              <Flex
                p="12px"
                border={"1px"}
                justifyContent={"space-between"}
                rounded={"md"}
                borderColor={"#D2D2D2"}
                my={3}>
                <Text color="#000" fontSize="14px" fontWeight="700">
                  Total
                </Text>
                <Text color="#000" fontSize="14px" fontWeight="500">
                  € {totalPayment}.00
                </Text>
              </Flex>

              <Button
                onClick={handleFormChange}
                bg="black"
                _hover={{
                  bg: "white",
                  color: "black",
                  border: "1px solid black",
                }}
                color="#F2F2F2"
                rounded="40px"
                py="12px"
                mt="20px"
                my={5}
                px="24px"
                w="100%"
                fontSize="14px"
                fontWeight="600">
                Debit or Credit Card
              </Button>

              <Button
                onClick={handleFormChange}
                _hover={{
                  bg: "#019CDE",
                  color: "#019CDE",
                }}
                bg="#019CDE"
                color="#F2F2F2"
                rounded="40px"
                py="12px"
                px="24px"
                w="100%"
                fontSize="14px"
                fontWeight="600">
                <svg
                  width="82"
                  height="21"
                  viewBox="0 0 82 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M30.6742 4.97266H26.1418C25.8317 4.97266 25.5679 5.19799 25.5195 5.50417L23.6864 17.1265C23.6499 17.3558 23.8276 17.5626 24.0602 17.5626H26.224C26.5342 17.5626 26.7979 17.3372 26.8463 17.0304L27.3407 13.8957C27.3884 13.5888 27.6528 13.3635 27.9623 13.3635H29.3972C32.3828 13.3635 34.1059 11.9187 34.5559 9.05575C34.7587 7.80318 34.5645 6.81903 33.978 6.12979C33.3338 5.37295 32.1912 4.97266 30.6742 4.97266ZM31.1971 9.21745C30.9493 10.8438 29.7067 10.8438 28.5051 10.8438H27.8212L28.301 7.8065C28.3295 7.62292 28.4886 7.48772 28.6741 7.48772H28.9876C29.8061 7.48772 30.5781 7.48772 30.9771 7.95429C31.215 8.23263 31.2879 8.64618 31.1971 9.21745Z"
                    fill="white"
                  />
                  <path
                    d="M44.2217 9.16506H42.0513C41.8664 9.16506 41.7067 9.30026 41.6782 9.48383L41.5821 10.0909L41.4303 9.87087C40.9604 9.18892 39.9127 8.96094 38.8669 8.96094C36.4685 8.96094 34.4199 10.7775 34.021 13.3257C33.8135 14.5968 34.1085 15.8123 34.8295 16.6599C35.4909 17.4393 36.4373 17.764 37.5633 17.764C39.4958 17.764 40.5674 16.5214 40.5674 16.5214L40.4707 17.1245C40.4342 17.3551 40.6118 17.5619 40.8431 17.5619H42.7982C43.109 17.5619 43.3715 17.3365 43.4205 17.0297L44.5935 9.60114C44.6307 9.37249 44.4537 9.16506 44.2217 9.16506ZM41.1964 13.3893C40.987 14.6293 40.0028 15.4617 38.7476 15.4617C38.1173 15.4617 37.6137 15.2595 37.2902 14.8765C36.9695 14.4961 36.8475 13.9546 36.9496 13.3515C37.1451 12.1222 38.1458 11.2626 39.3818 11.2626C39.9982 11.2626 40.4992 11.4674 40.8292 11.8538C41.1599 12.2441 41.2911 12.7889 41.1964 13.3893Z"
                    fill="white"
                  />
                  <path
                    d="M55.7813 9.16504H53.6003C53.3922 9.16504 53.1967 9.26843 53.0787 9.4414L50.0706 13.8724L48.7955 9.61437C48.7153 9.34795 48.4694 9.16504 48.1911 9.16504H46.0478C45.7873 9.16504 45.6064 9.41953 45.6892 9.66474L48.0916 16.7149L45.8331 19.9033C45.6554 20.1545 45.8344 20.4997 46.1412 20.4997H48.3196C48.5264 20.4997 48.7199 20.399 48.8372 20.2293L56.0915 9.75818C56.2651 9.50767 56.0868 9.16504 55.7813 9.16504Z"
                    fill="white"
                  />
                  <path
                    d="M63.0024 4.97266H58.4693C58.1598 4.97266 57.896 5.19799 57.8476 5.50417L56.0145 17.1265C55.9781 17.3558 56.1557 17.5626 56.387 17.5626H58.7132C58.9292 17.5626 59.1141 17.4048 59.1479 17.1901L59.6682 13.8957C59.7159 13.5888 59.9803 13.3635 60.2898 13.3635H61.724C64.7102 13.3635 66.4327 11.9187 66.8833 9.05575C67.0868 7.80318 66.8913 6.81903 66.3048 6.12979C65.6612 5.37295 64.5194 4.97266 63.0024 4.97266ZM63.5253 9.21745C63.2781 10.8438 62.0354 10.8438 60.8332 10.8438H60.15L60.6305 7.8065C60.659 7.62292 60.8167 7.48772 61.0029 7.48772H61.3164C62.1342 7.48772 62.9069 7.48772 63.3059 7.95429C63.5438 8.23263 63.6161 8.64618 63.5253 9.21745Z"
                    fill="white"
                  />
                  <path
                    d="M76.55 9.16506H74.3809C74.1946 9.16506 74.0362 9.30026 74.0084 9.48383L73.9123 10.0909L73.7599 9.87087C73.29 9.18892 72.2429 8.96094 71.1971 8.96094C68.7987 8.96094 66.7508 10.7775 66.3519 13.3257C66.1451 14.5968 66.4387 15.8123 67.1597 16.6599C67.8225 17.4393 68.7675 17.764 69.8935 17.764C71.826 17.764 72.8977 16.5214 72.8977 16.5214L72.8009 17.1245C72.7644 17.3551 72.9421 17.5619 73.1747 17.5619H75.1291C75.4386 17.5619 75.7023 17.3365 75.7507 17.0297L76.9244 9.60114C76.9602 9.37249 76.7826 9.16506 76.55 9.16506ZM73.5246 13.3893C73.3165 14.6293 72.331 15.4617 71.0758 15.4617C70.4469 15.4617 69.9419 15.2595 69.6185 14.8765C69.2977 14.4961 69.1771 13.9546 69.2778 13.3515C69.4746 12.1222 70.474 11.2626 71.71 11.2626C72.3264 11.2626 72.8274 11.4674 73.1574 11.8538C73.4895 12.2441 73.6207 12.7889 73.5246 13.3893Z"
                    fill="white"
                  />
                  <path
                    d="M79.1082 5.29112L77.2479 17.1262C77.2115 17.3555 77.3891 17.5623 77.6204 17.5623H79.4906C79.8014 17.5623 80.0652 17.3369 80.1129 17.0301L81.9474 5.40842C81.9838 5.17911 81.8062 4.97168 81.5749 4.97168H79.4807C79.2958 4.97234 79.1367 5.10754 79.1082 5.29112Z"
                    fill="white"
                  />
                  <path
                    d="M4.86323 19.8214L5.20984 17.6198L4.43775 17.6019H0.750977L3.3131 1.35632C3.32105 1.30728 3.3469 1.26155 3.38467 1.22908C3.42245 1.1966 3.47083 1.17871 3.5212 1.17871H9.73762C11.8014 1.17871 13.2256 1.60816 13.9692 2.4558C14.3178 2.85343 14.5398 3.26897 14.6471 3.72625C14.7598 4.20607 14.7618 4.77933 14.6518 5.47852L14.6438 5.52955V5.97755L14.9924 6.17505C15.286 6.33079 15.5193 6.50906 15.6982 6.71318C15.9965 7.05317 16.1893 7.48527 16.2708 7.99756C16.355 8.52443 16.3272 9.15138 16.1893 9.86116C16.0303 10.6776 15.7731 11.3888 15.4258 11.9706C15.1064 12.5068 14.6995 12.9515 14.2164 13.2961C13.7551 13.6235 13.207 13.872 12.5874 14.0311C11.9869 14.1875 11.3023 14.2663 10.5515 14.2663H10.0677C9.72171 14.2663 9.38571 14.3909 9.12194 14.6143C8.85751 14.8423 8.68255 15.1537 8.62887 15.4944L8.59242 15.6925L7.98006 19.5728L7.95222 19.7153C7.94493 19.7604 7.93234 19.7829 7.91378 19.7982C7.89721 19.8121 7.87336 19.8214 7.85016 19.8214H4.86323Z"
                    fill="white"
                  />
                  <path
                    d="M15.3219 5.58105C15.3033 5.69968 15.2821 5.82096 15.2583 5.94556C14.4385 10.1546 11.6338 11.6086 8.05171 11.6086H6.22787C5.7898 11.6086 5.42066 11.9267 5.3524 12.3588L4.41861 18.281L4.15418 19.9597C4.10978 20.2433 4.32848 20.4991 4.61478 20.4991H7.84957C8.23263 20.4991 8.55803 20.2208 8.61834 19.843L8.65015 19.6787L9.2592 15.8136L9.29831 15.6016C9.35795 15.2225 9.68402 14.9441 10.0671 14.9441H10.5509C13.6849 14.9441 16.1384 13.6717 16.8554 9.98955C17.155 8.45135 16.9999 7.16697 16.2073 6.26367C15.9674 5.99129 15.6698 5.76529 15.3219 5.58105Z"
                    fill="#BFE6F7"
                  />
                  <path
                    d="M14.4644 5.23957C14.3392 5.20312 14.21 5.16998 14.0774 5.14016C13.9442 5.111 13.8077 5.08515 13.6672 5.06262C13.1754 4.98309 12.6366 4.94531 12.0594 4.94531H7.18698C7.06703 4.94531 6.95304 4.97248 6.85098 5.02153C6.62631 5.12955 6.4593 5.34229 6.41887 5.60274L5.38236 12.1678L5.35254 12.3593C5.4208 11.9272 5.78994 11.6091 6.22801 11.6091H8.05185C11.6339 11.6091 14.4386 10.1544 15.2584 5.94604C15.2829 5.82144 15.3035 5.70016 15.322 5.58154C15.1146 5.47152 14.8899 5.37741 14.648 5.29722C14.5884 5.27734 14.5267 5.25812 14.4644 5.23957Z"
                    fill="#EBF5FE"
                  />
                  <path
                    d="M6.41935 5.60237C6.45977 5.34192 6.62678 5.12918 6.85145 5.02182C6.95417 4.97278 7.0675 4.94561 7.18745 4.94561H12.0599C12.6371 4.94561 13.1759 4.98338 13.6676 5.06291C13.8081 5.08544 13.9447 5.11129 14.0779 5.14045C14.2104 5.17027 14.3397 5.20341 14.4649 5.23986C14.5272 5.25841 14.5888 5.27763 14.6492 5.29685C14.891 5.37704 15.1157 5.47181 15.3231 5.58116C15.567 4.02573 15.3212 2.96669 14.4802 2.00771C13.553 0.951983 11.8796 0.5 9.73831 0.5H3.52188C3.08448 0.5 2.71136 0.818111 2.64376 1.25088L0.0544715 17.6634C0.00344112 17.9882 0.253954 18.2811 0.581343 18.2811H4.41922L5.38284 12.1674L6.41935 5.60237Z"
                    fill="white"
                  />
                </svg>
              </Button>
              <Text
                color="link"
                fontSize="12px"
                fontWeight="400"
                textAlign="center"
                mt="24px">
                By booking a session, you agree to our{" "}
                <a href="">
                  <u>Terms & Conditions</u>
                </a>
              </Text>
            </Box>
          </Flex>
        </ModalContent>
      </Modal>
    </Box>
  );
}
